<template>
    <v-app id="inspire">
        <v-dialog v-model="pleaseWait" persistent width="400" >
            <v-container grid-list-md text-center>
                <v-layout row wrap>

                <v-flex xs12>
                <v-card>        
                    <v-card-text class="text-lg-center headline" dir="ltr">
                        {{ $t("please-wait") }}<br/><br/>
                    <v-progress-circular :size="90" :width="7" color="#079038" indeterminate></v-progress-circular>
                    </v-card-text>
                </v-card>
                </v-flex>      

                </v-layout>
            </v-container>
        </v-dialog>


        <v-dialog v-model="showRenameTable" persistent max-width="700px">
            <v-card>
                <v-card-title class="headline grey lighten-2" style="color: black" >
                {{ $t("dashboard.rename-table") }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="background-color: #373737">
                    <v-layout style="padding-top: 10px">
                        <v-row no-gutters dense>
                            <v-col cols="12"
                                ><v-text-field v-if="selectedEditTableName != null"
                                    v-model="selectedEditTableName.Name"
                                    solo
                                    dense
                                    rounded
                                    :label="$t('dashboard.enter-table-name')"
                                ></v-text-field
                            ></v-col>
                        </v-row>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" dense rounded @click="showRenameTable = false">{{ $t("general.cancel") }}</v-btn>
                    <span style="width: 10px"></span>
                    <v-btn color="blue darken-1" dense rounded @click="updateTableName()">{{ $t("general.update") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="showStreamingSettings" persistent max-width="700px">
            <v-card>
                <v-card-title class="headline grey lighten-2" style="color: black" >
                {{ $t("dashboard.streaming-settings") }}
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text style="background-color: #373737">
                    <v-layout style="padding-top: 10px">
                        <v-row no-gutters dense>
                            <v-col cols="12" style="padding-bottom: 20px">
                                {{ $t("dashboard.get-streaming-info") }} <a href="https://www.youtube.com/live_dashboard" target="_">YouTube</a> {{ $t("general.or") }} <a href="https://www.facebook.com/live/producer" target="_">Facebook</a>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="streaming.server" background-color="#1e1e1e" filled outlined dense rounded  :label="$t('dashboard.server-address')" ></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-text-field v-model="streaming.key" background-color="#1e1e1e" filled outlined dense rounded  :label="$t('dashboard.streaming-key')"></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn color="blue darken-1" dense rounded @click="updateTableStreamingInfo(settingsTableId)">{{ $t("general.update") }}</v-btn>
                            </v-col>                            
                        </v-row>
                    </v-layout>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="red darken-1" dense rounded @click="showStreamingSettings = false">{{ $t("general.close") }}</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>        


        <v-navigation-drawer v-model="drawer" app :right="$t('left-direction') == 'right'" >
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title class="title" style="display: flex">
                            <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->
                            <v-img
                                :src="require('../assets/logo-main.png')"
                                contain
                                max-width=150
                                @click="drawer = !drawer"
                            ></v-img>
                        </v-list-item-title>
                        <v-list-item-subtitle style="margin-top: 5px">
                            {{ loggedInUser.name }}
                        </v-list-item-subtitle>
                    </v-list-item-content>
                </v-list-item>

                <v-divider></v-divider>

                <v-list dense nav>
                    <v-list-item v-for="item in items" :key="item.title" link  @click="activeSection = item.section">
                        <v-list-item-icon>
                            <v-icon>{{ item.icon }}</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                item.title
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                        v-if="showBillingMenu"
                        link
                        @click="goToBilling"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-account-cash</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("billing")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                    <v-list-item
                        link
                        @click="doLogout"
                    >
                        <v-list-item-icon>
                            <v-icon>mdi-logout</v-icon>
                        </v-list-item-icon>

                        <v-list-item-content>
                            <v-list-item-title>{{
                                $t("logout")
                            }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>                    
                </v-list>
        </v-navigation-drawer>

        <v-app-bar app>
            <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
            <v-toolbar-title style="margin:0px; padding: 0px;">
                <v-img v-if="!drawer"
                    :src="require('../assets/logo-main.png')"
                    contain
                    style="max-width: 150px"
                ></v-img>
            </v-toolbar-title>
        </v-app-bar>
        <v-main style="margin: 0px; padding: 20px">
            <v-flex align-self-start v-if="activeSection == 'dashboard'">
                <v-layout wrap column class="fill-height">
                    <div style="font-size: 24pt">{{ $t("dashboard.title") }}</div>
                    <v-divider></v-divider>                    
                    <v-container v-if="showCompleteOrder" class="py-8 px-6" fluid>
                        <v-row no-gutters dense>
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                <div>{{ $t("dashboard.order-complete-detect") }}</div>
                                <v-btn color="success" rounded @click="completeOrder()" >{{ $t("dashboard.complete-order") }}</v-btn>
                            </v-col>
                        </v-row>                        
                    </v-container>

                    <v-container v-if="showClubPurchase" class="py-8 px-6" fluid>
                        <v-row no-gutters dense>
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                <div>{{ $t("dashboard.no-club-detect") }}</div>
                                <v-btn color="success" rounded @click="buyNow()" >{{ $t("dashboard.buy-now") }}</v-btn>
                            </v-col>
                        </v-row>                        
                    </v-container>                    

                    

                    <v-container v-if="loggedInUser.clubAdmin != ''" class="py-8 px-0" fluid>
                        <v-row no-gutters dense>
                            <v-col cols="12" style="position: relative !important">
                                <div v-if="clubPicture != null" style="position: absolute; top: 0px; width: 204px; height: 30px; z-index: 3; " >
                                    <div @click="removeClubLogo()" style="cursor: pointer; position: absolute; right:-10px; top: -10px; background-color: #b51f1f; width: 30px; height: 30px; border-radius: 15px; display: flex; justify-content: center; z-index: 3;">
                                        <v-icon>mdi-close</v-icon>
                                    </div>
                                </div>
                                <image-uploader
                                    :preview="false"
                                    :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                                    capture="user"
                                    :debug="0"
                                    doNotResize="gif"
                                    :autoRotate="true"
                                    outputFormat="verbose"
                                    @input="setImage"
                                    style="width: 204px; height: 116px;"
                                >

                                    <label for="fileInput" slot="upload-label">
                                    <figure>
                                        <div :style="logoStyle">
                                            <div style="position: relative; z-index: 1; display: flex; justify-content: center">
                                                <img ref="imgRef" id="imgRef" v-bind:src="clubPicture" v-show="clubPicture != null">
                                            </div>
                                            <div :style="'position: absolute; z-index: 2; display: flex; align-items: ' + ((clubPicture != null) ? 'flex-end' : 'center') + '; justify-content: center; top: 0px; left: 0px; width: 100%; height: 100%'">
                                                <div :style="(clubPicture) ? 'background-color: black; padding: 5px; border-radius: 10px' : ''">
                                                    {{ clubPicture ? $t("dashboard.change-logo") : $t("dashboard.add-logo") }}
                                                </div>
                                            </div>
                                        </div>
                                        <!--                                    
                                        <v-icon v-if="clubPicture == null">image</v-icon>
                                        <span class="upload-caption">{{ hasImage ? "Change Logo" : "Add Logo"}}</span>
                                        -->


                                    </figure>
                                    
                                    </label>
                                </image-uploader>
                                <!-- <v-file-input rounded solo dense truncate-length="40" accept="image/*"  prepend-icon="mdi-image"  label="Select Picture"></v-file-input> -->
                            </v-col>
                            <v-col cols="12">
                                <v-row no-gutters dense style="margin-top: 10px">
                                    <v-col cols="12" :style="{fontSize: titleTextSize}">
                                        {{ $t("dashboard.club-name") }}
                                    </v-col>
                                </v-row>
                                
                                <v-row no-gutters dense>
                                    <v-text-field
                                        v-model="clubName"
                                        solo
                                        dense
                                        rounded
                                        :label="$t('dashboard.enter-club-name')"
                                    ></v-text-field>
                                    <v-btn color="success" :style="[ $t('rtl') == 'false' ? { marginLeft: '10px', 'textAlign': 'left', 'paddingRight': '10px' } : { marginRight: '10px', 'textAlign': 'right', 'paddingRight': '10px'}]" rounded @click="updateClubName()" :disabled="clubName == compClubName">{{  $t("general.update") }}</v-btn>                                
                                </v-row>
                            </v-col>
                        </v-row>
                        <!--
                        <v-row no-gutters dense>
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                Club Logo
                            </v-col>
                        </v-row>
                        <v-row no-gutters dense style="margin-top: 10px">
                            <v-col cols="9">
                            <image-uploader
                                :preview="false"
                                :className="['fileinput', { 'fileinput--loaded': hasImage }]"
                                capture="user"
                                :debug="0"
                                doNotResize="gif"
                                :autoRotate="true"
                                outputFormat="verbose"
                                @input="setImage"
                            >

                                <label for="fileInput" slot="upload-label">
                                <figure>
                                    <div style="width: 200ox; height: 114px">
                                        <img ref="imgRef" id="imgRef" v-bind:src="clubPicture" v-show="clubPicture != null">
                                    </div>
                                    
                                    <v-icon v-if="clubPicture == null">image</v-icon>
                                    <span class="upload-caption">{{ hasImage ? "Change Logo" : "Add Logo"}}</span>                                    
                                </figure>
                                
                                </label>              
                            </image-uploader>                                
                            </v-col>
                        </v-row>
                        -->

                        <v-row no-gutters dense v-if="clubInfo != null">
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                {{ $t("dashboard.my-tables")}}
                            </v-col>
                            <v-col cols="12">
                                <v-simple-table>
                                    <!-- <template v-slot:default> -->
                                        <thead>
                                            <tr>
                                                <th width="50%" :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("dashboard.name")}}</th>
                                                <th width="40%" :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("dashboard.status")}}</th>
                                                <th width="5%" :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("dashboard.ver")}}</th>
                                                <th width="5%" :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("dashboard.actions")}}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <template  v-for="(table, i) in clubInfo.Tables">
                                            <tr :key="table.Id"  >
                                                <td>{{ table.Name }}</td>
                                                <td>
                                                    <div style="display: flex; align-items: center ">
                                                        <div>{{ table.Status.Text }}</div>
                                                        <div v-if="table.Status.Streaming" style="margin-left: 10px" title="Streaming"><v-icon medium color="red darken-2">mdi-access-point</v-icon></div>
                                                        <div v-if="table.Status.Recording" title="Recording" style="background-color: red; width: 10px; height: 10px; border-radius: 5px; margin-left: 10px"></div>
                                                    </div>
                                                </td>
                                                <td>{{ table.Status.Version }}</td>
                                                <td style="display: flex; align-items: center">
                                                    <v-menu offset-y>
                                                        <template v-slot:activator="{ on, attrs }">
                                                            <v-btn dark icon v-bind="attrs" v-on="on">
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list>
                                                            <v-list-item style="cursor: pointer" @click="refreshTable(table.PublicId)">
                                                                <v-list-item-title>{{ $t("general.refresh") }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item  :disabled="table.Status.GameId != ''" style="cursor: pointer"  @click="showVideoOnTV(table.PublicId)">
                                                                <v-list-item-title>{{ rtcON ? $t("dashboard.tv-hide") : $t("dashboard.tv-show") }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item  v-if="table.Status.GameId != ''" @click="endGameAsk(table.Status.GameId)" style="cursor: pointer">
                                                                <v-list-item-title >{{ $t("dashboard.end-game") }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item style="cursor: pointer" @click="showTableStreamingSettings(table.Id)">
                                                                <v-list-item-title >{{ $t("dashboard.streaming-settings") }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item :disabled="table.Status.GameId == '' || table.Status.VersionParsed < 10002" style="cursor: pointer" @click="toggleStreaming(table.Id, table.PublicId, !table.Status.Streaming, i)">
                                                                <v-list-item-title >{{ table.Status.Streaming ? $t("dashboard.stop") : $t("dashboard.start") }} {{ $t("dashboard.streaming") }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item  v-if="table.Status.GameId != ''" @click="openGraphicsLink(table.Status.GameId)"  style="cursor: pointer">
                                                                <v-list-item-title >{{ $t("dashboard.graphics") }}</v-list-item-title>
                                                            </v-list-item>
                                                            <v-list-item style="cursor: pointer" @click="editTableName(i)">
                                                                <v-list-item-title >{{ $t("dashboard.rename") }}</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                    <v-btn dark icon @click="expandRow(i)">
                                                        <v-icon v-if="selectedExpandRow == i">mdi-chevron-up</v-icon>
                                                        <v-icon v-if="selectedExpandRow != i">mdi-chevron-down</v-icon>
                                                    </v-btn>
                                                </td>
                                            </tr>
                                            <tr v-show="selectedExpandRow == i" :key="'expandable_' + table.Id">
                                                <td colspan="4"> 
                                                    <div style="display: flex">
                                                        <div style="margin-right: 10px">{{ $t("dashboard.active-players") }}:</div>
                                                        <div :style="'width: 30%; display: flex; align-items: center; order:' + (i+1)" class=""  :key="i + '____' + table.Id">
                                                            <b style="color: #ffc600">{{ table.Status.Players.length > 0 ? table.Status.Players[0].Name : '' }}</b>
                                                            <span style="margin-left: 5px; margin-right: 5px">{{ table.Status.Players.length == 2 ? ' vs ' : '' }}</span>
                                                            <b style="color: #ffc600">{{ table.Status.Players.length == 2 ? table.Status.Players[1].Name : '' }}</b>
                                                        </div>                                                

                                                    </div>
                                                    
                                                </td>
                                            </tr>
                                            </template>
                                        </tbody>
                                    <!-- </template> -->
                                </v-simple-table>
                            </v-col>
                        </v-row>

                    </v-container>


                    <v-container class="py-0 px-0" fluid>
                        <v-row no-gutters dense>                        
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                {{ $t("dashboard.download-main-app") }}:
                            </v-col>                            
                        </v-row>
                        <v-row no-gutters dense>
                            <v-col cols="12" style="font-size: 14pt; display: flex">
                                <a href="https://apps.apple.com/us/app/snoocare/id1549386880" target="_blank"><img :src="require('../assets/images/apple-store-badge.png')" :style="{ height: badgeSize}" /></a>
                                <div style="width: 10px"></div>
                                <a href="https://play.google.com/store/apps/details?id=snoocare.pixellot.tv" target="_blank"><img :src="require('../assets/images/google-play-badge.png')" :style="{ height: badgeSize}" /></a>
                            </v-col>
                        </v-row>
                        <v-row no-gutters dense>                        
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                {{ $t("dashboard.download-table-app") }}:
                            </v-col>                            
                        </v-row>
                        <v-row no-gutters dense>
                            <v-col cols="12" style="font-size: 14pt; display: flex">
                                <a href="https://apps.apple.com/us/app/snoocare-table-viewer/id1552568517" target="_blank"><img :src="require('../assets/images/apple-store-badge.png')" :style="{ height: badgeSize}" /></a>
                                <div style="width: 10px"></div>
                                <a href="https://play.google.com/store/apps/details?id=snoocareviewer.pixellot.tv" target="_blank"><img :src="require('../assets/images/google-play-badge.png')" :style="{ height: badgeSize}" /></a>
                            </v-col>
                        </v-row>                        
                    </v-container>
                </v-layout>
            </v-flex> <!-- DASHBOARD -->

            <!-- SUPPORT -->
            
            <v-flex align-self-start style="margin: 10px" v-if="activeSection == 'support'" >
                <v-layout wrap column class="fill-height">
                    <div style="font-size: 24pt">{{ $t("support.title") }}</div>
                    <v-divider></v-divider>

                    <v-container v-if="loggedInUser.clubAdmin != ''" class="py-8 px-0" fluid>
                        <v-row no-gutters dense>
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                {{ $t("support.tutorials") }}
                            </v-col>
                        </v-row>
                        <v-row no-gutters dense style="margin-left: 20px"  v-for="tutorial in tutorials" :key="tutorial.id">
                            <v-col cols="12" :style="{fontSize: normalTextSize}">
                                <a v-if="tutorial.type == 'Video'" @click="playVideo(tutorial.videoId)"> - {{ tutorial.type }}: {{ tutorial.text }}</a>
                                <a v-if="tutorial.type != 'Video'" :href="tutorial.link" target="_" style="text-decoration: none"> - {{ tutorial.type }}: {{ tutorial.text }}</a>
                            </v-col>
                        </v-row>

                        <v-row no-gutters dense style="margin-top: 30px">
                            <v-col cols="12" :style="{fontSize: titleTextSize}">
                                {{ $t("support.contact-us") }}:
                            </v-col>
                        </v-row>
                        <v-row no-gutters dense style="margin-left: 20px">
                            <v-col cols="12" :style="{fontSize: normalTextSize}">
                                <span>{{ $t("email") }}: </span> <a href="mailto:support@snoocare.com">support@snoocare.com</a>
                            </v-col>
                        </v-row>
                        <v-row no-gutters dense style="margin-left: 20px">
                            <v-col cols="12" :style="{fontSize: normalTextSize}">
                                <span>{{ $t("facebook") }}: </span> <a href="https://www.facebook.com/SnooCare" target="_">https://www.facebook.com/SnooCare</a>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-dialog v-model="youtubeDialog"  width="640" @click:outside="stopVideo">
                        <v-card>
                            <youtube :video-id="videoId" :player-vars="playerVars"  ref="youtube"></youtube>
                            <v-divider></v-divider>

                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="success"
                                    rounded
                                    @click="stopVideo"
                                >
                                   {{ $t("general.close") }}
                                </v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-layout>
            </v-flex>
            <!-- SUPPORT -->
      
            <!-- USAGE -->
            
            <v-flex align-self-start style="margin: 10px" v-if="activeSection == 'usage'" >
                <v-layout wrap column class="fill-height">
                    <div style="font-size: 24pt">{{ $t("usage-page.title")}}</div>
                    <v-divider></v-divider>

                    <v-container v-if="loggedInUser.clubAdmin != ''" class="py-8 px-0" fluid>
                        <v-row no-gutters dense style="margin-top: 0px">
                            <v-col cols="12" :style="{fontSize: titleTextSize}" v-if="clubUsage != null">
                                <v-card  elevation="2"  outlined  shaped v-if="flase">
                                    <v-card-text>
                                        <p class="text-h5 text--primary">
                                            Global Cloud Storage
                                        </p>
                                        <p>Total storage: <span style="color: orange; font-weight: bold">{{ globalStorage }}</span></p>
                                        <!-- <p>Estimated cost: <span style="color: orange; font-weight: bold">{{ estimatedCost }}</span></p> -->
                                        <div>
                                            <apexchart type="donut" :options="globalStorageChartOptions" :series="globalStorageSeries"></apexchart> <!--style="width: 400px !important"-->
                                        </div>
                                    </v-card-text>    
                                </v-card>   

                                <div style="height: 30px" v-if="false"></div>

                                <v-card  elevation="2"  outlined  shaped>
                                    <v-card-text>
                                        <p class="text-h5 text--primary">
                                            {{ $t("usage-page.month-usage") }} {{ $t(`month-name.${startOfMonth}`) }} 1 - {{ today }}
                                        </p>
                                        <p>{{ $t("usage-page.total-time") }}: <span style="color: orange; font-weight: bold">{{ clubUsage.TotalTimeFormated }}</span></p>
                                        <!-- <p>Estimated cost: <span style="color: orange; font-weight: bold">{{ estimatedCost }}</span></p> -->
                                        
                                        <p>{{ $t("usage-page.num-of-games") }}: {{ clubUsage.TotalGames }} </p>
                                        <v-simple-table dark fixed-header height="300px">
                                            <template v-slot:default>
                                            <thead>
                                                <tr>
                                                <th :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("usage-page.date") }}</th>
                                                <th :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("usage-page.date") }}</th>
                                                <th :class="$t('rtl') == 'true' ? 'text-right' : 'text-left'">{{ $t("usage-page.duration") }}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(item, index) in clubUsage.Games" :key="index + '-game'">
                                                <td><a :href="cleanDomain + '/player?id=' + item.Id" target="_">{{ item.Start }}</a></td>
                                                <td>{{ getTableName(item.Table) }}</td>
                                                <td>{{ item.Duration }}</td>
                                                </tr>
                                            </tbody>
                                            </template>
                                        </v-simple-table>
                                    </v-card-text>    
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-layout>
            </v-flex>
            <!-- USAGE -->

        </v-main>
    </v-app>
</template>

<!--
<template>
    
    <v-container style="margin: 0px; padding: 0; height: 100%">
        <v-layout wrap class="fill-height">
            
            <v-app-bar absolute color="black" dark >
                <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

                <v-toolbar-title>Collapsing Bar</v-toolbar-title>

                <v-spacer></v-spacer>
            </v-app-bar>

            <v-navigation-drawer temporary v-model="drawer" :mini-variant.sync="mini" absolute left>

            </v-navigation-drawer>




        </v-layout>
    </v-container>
</template>
-->
<script>
import { eventBus } from "../main";
import { mapGetters } from "vuex";
const cloneDeep = require('clone-deep');
var moment = require('moment');

const GB_VALUE = 1024.0;
const TB_VALUE = 1024.0 * 1024.0;

export default {
    data() {
        return {
            clubId: undefined,
            selectedExpandRow: -1,
            drawer: true,
            mini: false,
            items: [
                { title: this.$t("dashboard.title") , icon: "mdi-view-dashboard", section: "dashboard" },
                { title: this.$t("support.title"), icon: "mdi-lifebuoy", section: "support" },
                { title: this.$t("dashboard.usage"), icon: "mdi-finance", section: "usage" },
                /*{ title: "About", icon: "mdi-help-box" },*/
            ],
            tutorials: [
                { id: "tutorial-0", text: this.$t("support.tutorial-0"), type: "PDF", videoId: "", link: "https://snoocare.com/howto/installation.pdf" },
                { id: "tutorial-1", text: this.$t("support.tutorial-1"), type: "Video", videoId: "hA1HoQWzGJI" },
                { id: "tutorial-2", text: this.$t("support.tutorial-2"), type: "Video", videoId: "ZJCWix8YeGE" },
                { id: "tutorial-3", text: this.$t("support.tutorial-3"), type: "Video", videoId: "nj_uupuq0sQ" },
                { id: "tutorial-4", text: this.$t("support.tutorial-4"), type: "Video", videoId: "O7M65mwEHzM" }
            ],
            tableSettings: {},
            showFirstTimeWizard: true,
            streaming: {
                allow: true,
                server: "",
                key: "",
            },
            clubInfo: null,
            clubUsage: null,
            clubName: "",
            tableName: "",
            youtubeDialog: false,
            videoId: '',
            playerVars: {
                controls: 0
            },
            stripe: null,
            allProducts:[],
            selectedProduct: {
                name: "",
                image: "",
                price: 0,
                priceId: "",
                subscription: {
                    name: "",
                    price: 0,
                    priceId: ""
                },
                shipping: {
                    name: "",
                    image: "",
                    price: 0,
                    priceId: "",
                }
            },
            activeSection: "dashboard", //"usage", //"dashboard",
            rtcON: false,
            pleaseWait: false,
            showRenameTable: false,
            showStreamingSettings: false,
            editTableNameIndex: -1,
            selectedEditTableName: null,
            settingsTableId: "",

            clubPicture: null,

            globalStorageSeries: [10.20, 200],
            globalStorageChartOptions: {
                chart: {
                    type: 'donut',
                },
                dataLabels: {
                    enabled: false
                },
                labels: ["", ""],
                responsive: [{
                    breakpoint: 400,
                    options: {
                        chart: {
                            width: 250,
                        },                  
                        legend: {
                            show: true
                        }
                    }
                },
                {
                    breakpoint: 4000,
                    options: {
                        chart: {
                            width: 300
                        },
                        legend: {
                            show: true
                        }
                    }
                }],
                legend: {
                    labels: {
                        colors: "#ffffff",
                    },
                    position: 'top',
                    /*offsetY: 0,
                    height: 230,*/
                }
            },
        };
    },
    computed: {
        ...mapGetters({
            loggedInUser: "getLoggedInUser",
            currentDomain: "getCurrentDomain",
            cleanDomain: "getCleanDomain"
        }),
        getActiveClub() {
            var clubId = this.loggedInUser.clubAdmin;
            if (this.clubId != undefined && this.loggedInUser.isSuper) {
                clubId = this.clubId;
            }
            return clubId;
        },
        hasImage() {
            return (this.userPictureCropped != null && this.userPictureCropped != "");
        },
        size () {
            const size = {xs:'small',sm:'small', lg:'',xl:''}[this.$vuetify.breakpoint.name];
            return size ? { [size]: true } : {}
        },
        badgeSize() {
            const size = {xs:'40px',sm:'40px',lg:'60px',xl:'60px'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '40px';
        },
        titleTextSize() {
            const size = {xs:'12pt',sm:'12pt',lg:'14pt',xl:'14pt'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '14pt';
        },
        normalTextSize() {
            const size = {xs:'10pt',sm:'10pt',lg:'12pt',xl:'12pt'}[this.$vuetify.breakpoint.name];
            return size ? [size] : '12pt';
        },
        showBillingMenu() {
            return (
                this.loggedInUser.hasOwnProperty("stripe_customer") &&
                this.loggedInUser.stripe_customer != "" &&
                this.loggedInUser.clubAdmin != "" &&
                this.clubId == undefined
            );
        },
        showUsageMenu() {
            return (
                this.loggedInUser.hasOwnProperty("stripe_customer") &&
                this.loggedInUser.stripe_customer != "" &&
                this.loggedInUser.clubAdmin != ""
            );
        },        
        showCompleteOrder() {
            return (
                this.loggedInUser.hasOwnProperty("stripe_customer") &&
                this.loggedInUser.stripe_customer != "" && this.loggedInUser.stripe_customer != null &&
                (this.loggedInUser.clubAdmin == "" || this.loggedInUser.clubAdmin == null)
            );
        },
        showClubPurchase() {
            return this.loggedInUser.hasOwnProperty("stripe_customer") &&
            (this.loggedInUser.stripe_customer == "" || this.loggedInUser.stripe_customer == null);
        },
        youtubePlayer() {
            return this.$refs.youtube.player
        },
        compClubName() {
            if (this.clubInfo != null) {
                console.log(this.clubInfo);
                return this.clubInfo.Name;
            }
            return "";
        },
        compTableName() {
            if (this.clubInfo != null) {
                if ( this.editTableNameIndex > 0)
                    return this.clubInfo.Tables[editTableNameIndex].Name;
            }
            return "";
        },
        globalStorage() {
            var storage = -1;
            
            if (this.clubUsage != null) {
                storage = 0;
                for (var i = 0; i < this.clubUsage.Videos.length; i++) {
                    if (!isNaN(this.clubUsage.Videos[i].Size))
                        storage += this.clubUsage.Videos[i].Size;
                }
            }
            storage = storage / 1024.0 / 1024.0; // Convert to MB
            if (storage > TB_VALUE) {
                storage = (storage / TB_VALUE).toFixed(2) + " TB";
            } else if (storage > GB_VALUE) {
                storage = (storage / GB_VALUE).toFixed(2) + " GB";
            }
            return storage;
        },        
        logoStyle() {
            if (this.clubPicture) {
                return "width: 204px; height: 116px; padding: 0px; position: relative; background-color: gray";
            } else {
                return "width: 204px; height: 116px; padding: 0px; position: relative; border: 1px dashed white;";
            }
        },
        currentCDN() {
            var cdn = "";
            if (this.cleanDomain.indexOf('https://dev.snoocare') > -1) {
                cdn = this.cleanDomain.replace("https://", "https://cdn.");
            } else {
                cdn = this.cleanDomain.replace("https://", "https://cdn.prod.");
            }
            return cdn;
        },
        startOfMonth() {
            var date = new Date();
            var currMonthName  = moment().format('MMMM');
            var firstDay = new Date(date.getFullYear(), date.getMonth(), 1).getTime();
            //return moment(parseInt(firstDay)).format("DD/MM/YYYY");
            return currMonthName;
        },
        today() {
            return moment().format('DD');
        },
        estimatedCost() {
            return (this.clubUsage == null) ? "$0" : "$" + (((this.clubUsage.TotalTime / 1000.0) / 60.0 / 60.0) * 0.9).toFixed(2);
        }
    },
    mounted() {
        var self = this;
        this.$vuetify.rtl = this.$t("rtl") == "true";
        this.$nextTick(() => {
            self.clubId = self.$route.query.id;
            if (self.loggedInUser.clubAdmin != "" || self.clubId != undefined) {
                self.getClubInfo();
                self.getClubUsage();
            }
            //console.log(self.loggedInUser);
            self.loadClubLogo();
        });
    },
    created () {
        var self = this;
        eventBus.$on('ClubAdminDataArrived', (data) => {
            var parseVersion = function(ver) {
                var parts = ver.split('.');
                var parsed = 0;
                var mult = 1;
                for (var i = 0; i < parts.length; i++ ) {
                    parsed = parsed + parseInt(parts[i]) * mult;
                    mult = mult * 100;
                }
                return parsed;
            };

            if (self.clubInfo != null) {
                if (data.Type == "TableStatus") {
                    for (var i = 0; i < self.clubInfo.Tables.length; i++) {
                        if (data.TableId == self.clubInfo.Tables[i].PublicId) {

                            self.clubInfo.Tables[i].Status.Players = data.Status.Players;
                            self.clubInfo.Tables[i].Status.Recording = data.Status.Recording;
                            self.clubInfo.Tables[i].Status.RecordingFPS = data.Status.RecordingFPS;
                            self.clubInfo.Tables[i].Status.GameId = data.Status.GameId;
                            self.clubInfo.Tables[i].Status.Streaming = data.Status.Streaming;

                            var version = "1.0.1";
                            var versionParsed = parseVersion(version);
                            if (data.Status.hasOwnProperty("Version")) {
                                version = data.Status.Version;
                                versionParsed = parseVersion(version);
                            }
                            self.clubInfo.Tables[i].Status.Version = version;
                            self.clubInfo.Tables[i].Status.VersionParsed = versionParsed;

                            //console.log(`GAME ID: ${data.Status.GameId}`);
                            if (data.Status.GameId != null && data.Status.GameId != "") {
                                self.clubInfo.Tables[i].Status.Text = "In Game";
                            } else {
                                self.clubInfo.Tables[i].Status.Text = "Free (Online)";
                            }
                        }
                    }
                    self.$forceUpdate();
                    
                }
            }
        });
    },
    watch: {},
    methods: {
        expandRow(row) {
            this.selectedExpandRow = (this.selectedExpandRow == row) ? -1 : row;
        },
        showTableStreamingSettings(tableId) {
            var self = this;
            this.getTableSettings(tableId, function() {
                self.showStreamingSettings = true; 
                self.settingsTableId = tableId;
                
                if (self.tableSettings[tableId] != undefined && self.tableSettings[tableId] != null) {
                
                    if (self.tableSettings[tableId]["streaming_server"]) {
                        self.streaming.server = self.tableSettings[tableId]["streaming_server"];

                    }

                    if (self.tableSettings[tableId]["streaming_key"]) {
                        self.streaming.key = self.tableSettings[tableId]["streaming_key"];
                    }
                }
            });

        },
        showVideoOnTV(publicId) {
            var sendCommand = this.rtcON ? "RTC_SETUP_OFF" : "RTC_SETUP_ON";
            this.$store.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: sendCommand, TablePublicId: publicId }));
            this.rtcON = !this.rtcON;
        },
        refreshTable(publicId) {
            this.$store.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "RELOAD_PAGE", TablePublicId: publicId}));
        },
        forceDownloadClubLogo(publicId) {
            this.$store.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "DOWNLOAD_NEW_CLUB_LOGO", TablePublicId: publicId}));
        },
        forceRemoveClubLogo(publicId) {
            this.$store.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "REMOVE_CLUB_LOGO", TablePublicId: publicId}));
        },
        endGameAsk(gameId) {
            this.$store.dispatch("showMessageDialog", {
                Title: "End Game",
                Body: `You are about to end this game.\nAre you sure?`,
                Type: "warning",
                Callback: this.endGame,
                CallbackParams: { GameId: gameId }
            });
        },
        editTableName(i) {
            this.selectedEditTableName = cloneDeep(this.clubInfo.Tables[i]);
            this.showRenameTable = true;
        },
        buyNow() {
            this.$router.push('purchasing');
        },
        completeOrder() {
            var self = this;

            var options = {
                url: `${this.currentDomain}/payments/get-product-list`,
                method: 'GET',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                }
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    var data =  response.body;
                    self.stripe = Stripe(data.pk);                    
                    self.allProducts = data.products;
                    for (var i in self.allProducts) {
                        if (self.allProducts[i].price.type == "one_time" && self.allProducts[i].name != "Shipping") {
                            self.selectedProduct.name = self.allProducts[i].name;
                            self.selectedProduct.image = self.allProducts[i].image;
                            self.selectedProduct.price = self.allProducts[i].price.amount;
                            self.selectedProduct.priceId = self.allProducts[i].id;
                        } else if (self.allProducts[i].price.type == "one_time" && self.allProducts[i].name == "Shipping") {
                            self.selectedProduct.shipping.name = self.allProducts[i].name;
                            self.selectedProduct.shipping.image = self.allProducts[i].image;
                            self.selectedProduct.shipping.price = self.allProducts[i].price.amount;
                            self.selectedProduct.shipping.priceId = self.allProducts[i].id;
                        } else {
                            self.selectedProduct.subscription.name = self.allProducts[i].name;
                            self.selectedProduct.subscription.price = self.allProducts[i].price.amount;
                            self.selectedProduct.subscription.priceId = self.allProducts[i].id;
                        }
                    }

                    self.getPurchaseSession();
                }
            },
            response => {
                console.error(response.body);
            });
        },
        getPurchaseSession() {
            var self = this;

            var selectedProducts = [{ "price": this.selectedProduct.priceId, quantity: 1}, {"price": this.selectedProduct.subscription.priceId, quantity: 1}, { "price": this.selectedProduct.shipping.priceId, quantity: 1}];
            var options = {
                url: `${this.currentDomain}/payments/create-payment-session`,
                method: 'POST',
                headers: 
                {
                    'content-type': 'application/json',
                    'accept': 'application/json',
                },
                body: JSON.stringify({ "items": selectedProducts, "customer": this.loggedInUser.stripe_customer, "user_id": this.loggedInUser.id, "name": this.loggedInUser.name, "locale": this.$i18n.locale, "email": this.loggedInUser.email }),
            };
            this.$http(options).then((response) => {
                if (response.status == 200) {
                    //console.log(response);
                    self.stripe.redirectToCheckout({ sessionId: response.body.id });
                }
            },
            response => {
                this.$store.dispatch("showMessageDialog", {Title: this.$t("dialog-titles.error"), Body: this.$t(`errors.${response.body}`), Type: "error" });
            });
        },        
        updateTableName() {
            var self = this;
            
            if (this.selectedEditTableName.Name == "") 
                return;

            var options = {
                url: `${this.currentDomain}/update-table-name`,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
                body: JSON.stringify({ "publicId": this.selectedEditTableName.PublicId, "newName": this.selectedEditTableName.Name }),                
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        self.getClubInfo();
                        self.$store.dispatch('sendMessage', JSON.stringify({ Type: "ClubAdmin", Action: "RELOAD_PAGE", TablePublicId: self.selectedEditTableName.PublicId}));
                    }
                    self.showRenameTable = false;                    
                },
                (response) => {
                    console.error(response.body);
                    self.showRenameTable = false;
                }
            ); 
        },
        updateClubName() {
            var self = this;

            if (this.compClubName == "" || this.clubName == "") 
                return;

            var options = {
                url: `${this.currentDomain}/update-club-name`,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
                body: JSON.stringify({ "newName": this.clubName }),
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        self.getClubInfo();
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            ); 
        },
        endGame(payload) {
            if (payload.GameId != null)
            {
                var gameId = payload.GameId;
                var self = this;
                this.pleaseWait = true;
                this.$http.post(`${this.currentDomain}/game/end-frame`,{
                    gameId: gameId,
                },
                { headers: { 'Authorization': `Bearer ${self.loggedInUser.token}`} }
                ).then(response => {
                    if (response.status == 200)
                    {
                    if (response.body != null)
                    {
                        self.$http.post(`${this.currentDomain}/game/end-game`,{
                            gameId: gameId,
                        },
                        { headers: { 'Authorization': `Bearer ${self.loggedInUser.token}`} }
                        ).then(response => {
                            self.pleaseWait = false;
                            //console.log(response);
                        }, response => {
                            console.log("Error:");
                            console.log(response);
                            self.pleaseWait = false;
                            self.$store.dispatch("showMessageDialog", {Title: "Error while ending game", Body: response.body, Type: "error" });

                        }); 
                    }
                    }
                }, response => {
                    console.log("Error:");
                    console.log(response);
                    self.pleaseWait = false;
                    self.$store.dispatch("showMessageDialog", {Title: "Error while ending game", Body: response.body, Type: "error" });
                });             
            }
        },
            playVideo(id) {
            var self = this;
            this.videoId = id;
            this.youtubeDialog = true;
            setTimeout(() => {
                self.youtubePlayer.playVideo()
            }, 500);
            
        },
        updateStreamingDetails() {
            // this.$store.dispatch('sendMessage', JSON.stringify({ 
            //     Type: "CommandToTable", 
            //     Command: "UPDATE_STREAMING_INFO", 
            //     TablePublicId: this.clubInfo.Tables[0].PublicId, 
            //     Params: []
            // }));
        },
        toggleStreaming(tableId, publicTableId, start, i) {
            var self = this;

            var serverAddress = "";
            var streamingKey = "";
            if (this.tableSettings[tableId]["streaming_server"]) {
                serverAddress = this.tableSettings[tableId]["streaming_server"];
            }

            if (this.tableSettings[tableId]["streaming_key"]) {
                streamingKey = this.tableSettings[tableId]["streaming_key"];
            }            

            if (serverAddress != "") {
                if (start) {
                    this.startStreaming(publicTableId, serverAddress, streamingKey);
                    this.pleaseWait = true;
                    setTimeout(function() {
                        self.pleaseWait = false;
                        if (!self.clubInfo.Tables[i].Status.Streaming) {
                            self.$store.dispatch("showMessageDialog", {Title: "Streaming Error", Body: "Cannot start streaming", Type: "error" });
                        }
                    }, 5000);
                } else {
                    this.$store.dispatch("showMessageDialog", {
                        Title: "Stop Streaming",
                        Body: `You are about to end the stream.\nAre you sure?`,
                        Type: "warning",
                        Callback: this.stopStreaming,
                        CallbackParams: { publicTableId: publicTableId }
                    });
                }
            } else {
                this.$store.dispatch("showMessageDialog", {Title: "Streaming Error", Body: "No streaming server\nPlease update your streaming settings", Type: "error" });
            }
        },
        startStreaming(publicTableId, serverAddress, streamingKey) {
            this.$store.dispatch('sendMessage', JSON.stringify({ 
                Type: "CommandToTable", 
                Command: "START_STREAMING", 
                TablePublicId: publicTableId, 
                Params: [serverAddress, streamingKey]
            }));
        },

        stopStreaming(payload) {
            this.$store.dispatch('sendMessage', JSON.stringify({ 
                Type: "CommandToTable", 
                Command: "STOP_STREAMING", 
                TablePublicId: payload.publicTableId, 
                Params: []
            }));
        },
        stopVideo() {
            this.youtubeDialog = false;
            this.youtubePlayer.stopVideo();
        },
        playing() {
            console.log('\o/ we are watching!!!')
        },
        doLogout() {
            this.$store.dispatch("doLogOut");
        },
        openGraphicsLink(gameid)
        {
            window.open("https://snoocare.com/cg?gameid=" + gameid);
        },        
        getClubInfo() {
            var self = this;
            var clubId = this.getActiveClub;

            var options = {
                url: `${this.currentDomain}/get-club-info`,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
                body: JSON.stringify({ id: clubId }),                
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        self.clubInfo = response.body;
                        if (self.clubInfo != null) {
                            for (var i = 0; i < self.clubInfo.Tables.length; i++) {
                                self.clubInfo.Tables[i].Status = {
                                    GameId: "",
                                    Text: "Free",
                                    Recording: false,
                                    Streaming: false,
                                    Players: []
                                };
                                self.getTableSettings(self.clubInfo.Tables[i].Id)
                            }
                            self.clubName = self.clubInfo.Name;
                            self.$store.dispatch("openSocketAsClubAdmin", self.clubInfo.Tables);                 
                            //TOVI           
                        }
                        //self.showFirstTimeWizard = Object.keys(self.tableSettings).length == 0;
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            );            
        },
        getTableName(publicId) {
            if (this.clubInfo != null) {
                for (var i = 0; i < this.clubInfo.Tables.length; i++) {
                    if (this.clubInfo.Tables[i].PublicId == publicId) {
                        return this.clubInfo.Tables[i].Name;
                    }
                }
            } 
            return publicId;
        },
        millisToTime(ms) {
            var millis = Math.max(0, ms);
            return moment('2000-01-01 00:00:00').add(moment.duration(millis)).format('HH:mm:ss');            
        },        
        timestampToDate(timestamp) {
            return moment(parseInt(timestamp)).format("DD/MM/YYYY HH:mm:ss");
        },
        getClubUsage() {
            var self = this;
            var clubId = this.getActiveClub;


            var options = {
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
                params: { id: clubId },
            };
            this.$http.get(`${this.currentDomain}/get-club-usage`, options).then(
                (response) => {
                    if (response.status == 200) {
                        self.clubUsage = response.body;
                        if (self.clubUsage != null) {
                            self.clubUsage.TotalTimeFormated = self.millisToTime(self.clubUsage.TotalTime);
                            for (var i = 0; i < self.clubUsage.Games.length; i++) {
                                self.clubUsage.Games[i].Start = self.timestampToDate(self.clubUsage.Games[i].Start);
                                self.clubUsage.Games[i].End = self.timestampToDate(self.clubUsage.Games[i].End);
                                self.clubUsage.Games[i].Duration = self.millisToTime(self.clubUsage.Games[i].Duration);
                            }

                            var tablesStorage = {};
                            storage = 0;
                            for (var i = 0; i < self.clubUsage.Videos.length; i++) {
                                if (!isNaN(self.clubUsage.Videos[i].Size)) {
                                    var tableId = self.clubUsage.Videos[i].Table;
                                    if (!tablesStorage.hasOwnProperty(tableId))
                                        tablesStorage[tableId] = 0;

                                    tablesStorage[tableId] += self.clubUsage.Videos[i].Size;
                                }
                            }

                            self.globalStorageSeries = [];
                            self.globalStorageChartOptions.labels = [];
                            for (var k in tablesStorage) {
                                var storage = tablesStorage[k];
                                storage = parseFloat((storage / 1024.0 / 1024.0 / 1024.0).toFixed(2)); // Convert to GB
                                self.globalStorageSeries.push(storage);

                                var tableName = self.getTableName(k);
                                self.globalStorageChartOptions.labels.push(tableName);
                            }
                        }
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            );            
        },        
        goToUsage() {

        },
        goToBilling() {
            var options = {
                url: `${this.currentDomain}/payments/customer-portal`,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
                body: JSON.stringify({ locale: this.$i18n.locale }),
            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        var url = response.body.URL;
                        window.location.href = url;
                    }
                },
                (response) => {
                    console.error(response.body);
                }
            );
        },

        updateTableStreamingInfo(tableId) {
            var self = this;
            this.pleaseWait = true;
            this.updateTableSetting(tableId, "streaming_server", this.streaming.server);
            this.updateTableSetting(tableId, "streaming_key", this.streaming.key, function(err) {
                self.pleaseWait = false;
            });
        },

        updateTableSetting(tableId, setting, value, doneCallback) {
            if (tableId == "") {
                console.error("No table id");
                return;
            }
                
            var self = this;

            var options = {
                url: `${this.currentDomain}/update-table-setting`,
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    accept: "application/json",
                    Authorization: `Bearer ${this.loggedInUser.token}`,
                },
                body: JSON.stringify({ "settingName": setting, "settingValue": value, "tableId": tableId }),

            };
            this.$http(options).then(
                (response) => {
                    if (response.status == 200) {
                        if (typeof doneCallback == "function") {
                            doneCallback(false);
                        }
                        
                        self.getTableSettings(tableId);
                    }
                },
                (response) => {
                    if (typeof doneCallback == "function") {
                        doneCallback(true);
                    }
                    console.error(response.body);
                }
            );

        },
        
        getTableSettings(tableId, doneCallback) {
            if (tableId == "") {
                console.error("No table id");
                return;
            }

            var self = this;

            this.$http.get(`${self.currentDomain}/get-table-settings`, 
                {   params: {tableId: tableId}, 
                    headers: {'Authorization': `Bearer ${self.loggedInUser.token}`}
                }).
                then(response => {
                    if (response.status == 200) {
                        self.tableSettings[tableId] = cloneDeep(response.body);
                    }
                    if (typeof doneCallback == "function") {
                        doneCallback();
                    }

                }, response => {
                    console.error(response.body);
                    if (typeof doneCallback == "function") {
                        doneCallback();
                    }
                }
            );
        },
        resizeImageToDataUri(img, maxWidth) {
            var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');

            if (maxWidth == undefined)
                maxWidth = 200;

            var ratio = 148.0 / 85.0;
            
            //var maxWidth = 200;
            var maxHeight = maxWidth / ratio;
            
            var width = img.width;
            var height = img.height;
            
            const imageRatio = parseFloat(img.width) / parseFloat(img.height);

            if (width > maxWidth) {
                width = maxWidth;
                height = width / imageRatio;
            }

            if (height > maxHeight) {
                height = maxHeight;
                width = height * imageRatio;
            }

            canvas.width = width;
            canvas.height = height;
            ctx.drawImage(img, 0, 0, width, height);
            return canvas.toDataURL("image/png");
        },
        setImage(output) {
            var self = this;
            var img = new Image;
            img.onload = function() {
                self.clubPicture = self.resizeImageToDataUri(img);

                var canvas = document.createElement('canvas'),
                ctx = canvas.getContext('2d');
                canvas.width = img.width;
                canvas.height = img.height;
                ctx.drawImage(img, 0, 0, img.width, img.height);
                var data = canvas.toDataURL("image/png");
                
                var tables = [];
                for (var i = 0; i < self.clubInfo.Tables.length; i++) {
                    tables.push(self.clubInfo.Tables[i].PublicId);
                }

                self.$store.dispatch("updateClubLogo", { Id: self.getActiveClub, Picture: data, Tables: tables });
            };
            img.src = output.dataUrl;
        },
        loadClubLogo() {
            var self = this;
            var img = new Image;
            img.crossOrigin = "anonymous";
            img.onload = function() {
                self.clubPicture = self.resizeImageToDataUri(img);
                //self.$store.dispatch("updateClubLogo", { Id: self.loggedInUser.clubAdmin, Picture: self.clubPicture });
            };
            
            var t = Date.now();
            img.src = `${this.currentCDN}/Clubs/${self.getActiveClub}/assets/club_logo.png?t=${t}`;
        },
        removeClubLogo() {
            var tables = [];
            for (var i = 0; i < this.clubInfo.Tables.length; i++) {
                tables.push(this.clubInfo.Tables[i].PublicId);
            }

            this.$store.dispatch("removeClubLogo", { Id: this.getActiveClub, Tables: tables });
            this.clubPicture = null;
        }
    },
};
</script>
